@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Medium";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff")
    format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Bold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff")
    format("woff");
  font-weight: 700;
  font-style: normal;
}
.swiper-banner .swiper-pagination {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
  width: 70px;
  height: 35px;
  line-height: 35px;
  left: 25px;
  border-radius: 5rem;
  color: #777;
  font-size: 14px;
}
.swiper-banner .swiper-pagination span {
  font-family: "Pretendard-Bold";
  color: #222;
}

.swiper-banner .swiper-pagination .swiper-pagination-total {
  font-family: "Pretendard-Regular";
  color: #777;
}
/**/
.accordion__button {
  position: relative;
  background-color: white !important;
  border: 0;
  padding: 12px 24px !important;
  font-family: "Pretendard-Medium";
  font-size: 16px;
  outline: none;
}
.accordion__button[aria-expanded="true"],
.accordion__button:hover {
  color: #111;
  font-weight: 500;
}
.accordion__button:before {
  position: absolute;
  right: 30px !important;
  margin: -6px 0 0 !important;
  top: 50%;
  content: "";
  border-radius: 2px;
  height: 6px !important;
  width: 6px !important;
  border-bottom: 2px solid #777 !important;
  border-right: 2px solid #777 !important;
  transform: rotate(45deg) !important;
  z-index: 10;
}
.accordion__button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 5px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  border-radius: 50%;
  z-index: 9;
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  margin: -3px 0 0 !important;
  border-bottom: 2px solid #777 !important;
  border-right: 2px solid #777 !important;
  transform: rotate(-135deg) !important;
}
